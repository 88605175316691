// Generated by Framer (b35efa8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["GsZ4pWQX5"];

const variantClassNames = {GsZ4pWQX5: "framer-v-bpdniv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "GsZ4pWQX5", title: BPG5viJlA = "Customer Traffic", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "GsZ4pWQX5", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-iTn2R", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-bpdniv", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"GsZ4pWQX5"} ref={ref} style={{...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0FsdG9uZSBTZW1pIEJvbGQ=", "--framer-font-family": "\"Altone Semi Bold\", serif", "--framer-font-size": "28.85px", "--framer-line-height": "32.46px", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Customer Traffic</motion.p></React.Fragment>} className={"framer-18wcm3g"} data-framer-name={"Customer Traffic"} fonts={["CUSTOM;Altone Semi Bold"]} layoutDependency={layoutDependency} layoutId={"anK49Yvmh"} style={{"--extracted-r6o4lv": "rgb(32, 23, 71)", "--framer-paragraph-spacing": "0px"}} text={BPG5viJlA} transition={transition} verticalAlignment={"center"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-iTn2R [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-iTn2R * { box-sizing: border-box; }", ".framer-iTn2R .framer-walt8z { display: block; }", ".framer-iTn2R .framer-bpdniv { height: 65px; overflow: visible; position: relative; width: 237px; }", ".framer-iTn2R .framer-18wcm3g { bottom: 0px; flex: none; height: auto; overflow: hidden; position: absolute; right: 0px; white-space: pre-wrap; width: 237px; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 65
 * @framerIntrinsicWidth 237
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"BPG5viJlA":"title"}
 */
const FramerlmLYRVgxk: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerlmLYRVgxk;

FramerlmLYRVgxk.displayName = "Archive / Title";

FramerlmLYRVgxk.defaultProps = {height: 65, width: 237};

addPropertyControls(FramerlmLYRVgxk, {BPG5viJlA: {defaultValue: "Customer Traffic", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerlmLYRVgxk, [{family: "Altone Semi Bold", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/lmLYRVgxk:default", url: "assets/eLRPJ4AGPDPAzDyRKaAS9s7LY8.ttf"}, url: new URL("assets/eLRPJ4AGPDPAzDyRKaAS9s7LY8.ttf", import.meta.url).href}])